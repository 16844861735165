/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_MARKETING_CAMPAIGN = gql`
  mutation createMarketingCampaign(
    $sendingDate: DateTime!
    $mailId: Int!
    $contacts: Upload!
    $countryId: Int!
  ) {
    createMarketingCampaign(
      inputMarketingCampaign: {
        sendingDate: $sendingDate
        mailId: $mailId
        contacts: $contacts
        countryId: $countryId
      }
    ) {
      success
    }
  }
`;
