import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import Whitelist from '../components/whitelist';

const whitelistManagerTabs = (user) => [
  {
    id: 'whitelist',
    label: 'Whitelist',
    path: 'main',
    component: <Whitelist />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.change_ratewhitelist'),
  },
];

export default whitelistManagerTabs;
