import { gql } from '@apollo/client';

export const DISPLAY_INVOICE_RECOMMENDATIONS = gql`
  mutation DisplayInvoiceRecommendations($invoiceRecommendationsFile: Upload!) {
    displayInvoiceRecommendations(
      invoiceRecommendationsFile: $invoiceRecommendationsFile
    ) {
      invoiceRecommendations {
        totalCount
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const CONFIRM_INVOICE_RECOMMENDATIONS = gql`
  mutation ConfirmInvoiceRecommendations($invoiceRecommendationIds: [Int]!) {
    confirmInvoiceRecommendations(
      invoiceRecommendationIds: $invoiceRecommendationIds
    ) {
      invoiceRecommendations {
        edges {
          node {
            id
            invoice {
              id
            }
          }
        }
      }
    }
  }
`;

export const DECLINE_INVOICE_RECOMMENDATIONS = gql`
  mutation DeclineInvoiceRecommendations(
    $invoiceRecommendationIds: [Int]!
    $failureReason: String!
    $clientInterest: String!
    $details: String
  ) {
    declineInvoiceRecommendations(
      invoiceRecommendationIds: $invoiceRecommendationIds
      failureReason: $failureReason
      clientInterest: $clientInterest
      details: $details
    ) {
      invoiceRecommendations {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;
