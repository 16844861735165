import { calculateInvoiceAmounts, calculateCommission } from '@fingo/lib/helpers/invoice-amounts-calculation';
import { addMoneyWithCurrency } from '@fingo/lib/helpers';

const buildInvoicesDataToSimulate = (invoices) => (
  invoices.map((inv) => {
    const rates = {
      days: inv.days || 30,
      amountWithIva: inv.amountWithIva,
      defaultRate: inv.offer ? inv.offer.defaultRate : inv.preoffer.defaultRate,
      retentionRate: inv.offer ? inv.offer.retentionRate : inv.preoffer.retentionRate,
      monthlyRate: inv.offer ? inv.offer.monthlyRate : inv.preoffer.monthlyRate,
    };
    const {
      effectiveRate,
      retentionAmount,
      advanceAmount,
      amountMinusInterests,
      priceDifference,
    } = calculateInvoiceAmounts(rates);
    return ({
      ...inv,
      ...rates,
      effectiveRate,
      retentionAmount,
      advanceAmount,
      amountMinusInterests,
      priceDifference,
    });
  })
);

const buildSummaryData = (invoicesDataToSimulate) => {
  const totalAmountWithIva = invoicesDataToSimulate.reduce(
    (prev, curr) => addMoneyWithCurrency(prev, curr.amountWithIva),
    0,
  );
  const { company: { currentCommissionRules } } = invoicesDataToSimulate[0];
  const summaryData = [
    {
      label: 'Cantidad de facturas',
      isMoney: false,
      value: invoicesDataToSimulate.length,
    },
    {
      label: 'Monto total de facturas',
      isMoney: true,
      value: invoicesDataToSimulate.reduce((prev, curr) => prev + curr.amountWithIva.amount, 0),
    },
    {
      label: 'Retención total',
      isMoney: true,
      value: invoicesDataToSimulate.reduce((prev, curr) => prev + curr.retentionAmount, 0),
    },
    {
      label: 'Comisión',
      isMoney: true,
      value: calculateCommission(currentCommissionRules, totalAmountWithIva).amount,
    },
    {
      label: 'Diferencia de precio',
      isMoney: true,
      value: invoicesDataToSimulate.reduce((prev, curr) => prev + curr.priceDifference, 0),
    },
  ];
  return summaryData;
};

export { buildInvoicesDataToSimulate, buildSummaryData };
