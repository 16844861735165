import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import React from 'react';
import MarketingCampaignSwitch from '../components/marketing-campaign/MarketingCampaignSwitch';

const marketingCampaignTabs = (user) => [
  {
    id: 'marketing-campaign-manager',
    label: 'Campañas',
    path: 'campaigns',
    component: <MarketingCampaignSwitch />,
    disabled: false,
    show: userHasRoutePermission(user, 'marketing_campaign.view_marketingcampaign'),
  },
];

export default marketingCampaignTabs;
