import { ConfirmingPortfolio } from '@fingo/lib/views';
import React, { useState } from 'react';
import ContentCut from '@mui/icons-material/ContentCut';
import Chip from '@mui/material/Chip';
import { useMutation } from '@apollo/client';
import { SPLIT_CONFIRMING_OPERATION, CONFIRMING_PAYROLLS } from '@fingo/lib/graphql';
import CustomPayrollActions from './CustomPayrollActions';

const ConfirmingPayroll = () => {
  const [filters, setFilters] = useState({});
  const [splitConfirmingOperation] = useMutation(
    SPLIT_CONFIRMING_OPERATION,
    { refetchQueries: [CONFIRMING_PAYROLLS] },
  );

  return (
    <ConfirmingPortfolio
      DocumentActions={({ documents, documentsSelected }) => (
        <Chip
          color="success"
          variant="contained"
          label="Dividir operación"
          sx={{ width: '180px', alignSelf: 'flex-end' }}
          onClick={() => splitConfirmingOperation(
            { variables: { confirmingIds: documentsSelected } },
          )}
          avatar={<ContentCut sx={{ '&&': { color: 'white', height: '12px' } }} />}
          disabled={!documentsSelected.length || documents.length === documentsSelected.length}
        />
      )}
      queryCustomVariables={filters}
      includeHeaders={
      [
        'id',
        'company',
        'createdAt',
        'numberOfOperations',
        'totalAmount',
        'payrollConditonsEdit',
      ]
    }
      collapsibleHeaders={[
        'company',
        'invoiceCount',
        'totalAmount',
        'monthlyRate',
        'operationConditionsEdit',
      ]}
      Actions={() => <CustomPayrollActions filters={filters} setFilters={setFilters} />}
    />
  );
};

export default ConfirmingPayroll;
