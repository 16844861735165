import React from 'react';
import { hasShowSubRoutes } from '@fingo/lib/helpers/routes';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ListIcon from '@mui/icons-material/List';
import Compare from '@mui/icons-material/Compare';
import getCountryFromTld from '@fingo/lib/helpers/country-from-tld';
import {
  ContactabilityManager,
  CustomersManager,
  ExecutiveManager,
  InvoicesManager,
  InvoiceProfileManager,
  OrderingManager,
  WhitelistManager,
  WhatsAppManager,
  ContractsManager,
} from '../components';
import contactabilityManagerTabs from './contactabilityManager';
import customerManagerTabs from './customerManager';
import executiveManagerTabs from './executiveManager';
import InvoicesManagerTabs from './invoiceManager';
import orderingTabs from './ordering';
import MarketingCampaignManager from '../components/MarketingCampaignManager';
import marketingCampaignTabs from './marketingCampaign';
import InvoiceProfileManagerTabs from './invoiceProfileManager';
import whatsAppManagerTabs from './whatsappManager';
import whitelistManagerTabs from './whitelistManager';
import PreSimulationsManager from '../components/PreSimulationsManager';
import contractManagerTabs from './contractManager';
import simulationTabs from './simulationTabs';

const commercialRoutes = (user) => [
  {
    id: 'marketing-campaign',
    label: 'Campañas masivas',
    path: 'marketing',
    icon: <CampaignIcon />,
    component: <MarketingCampaignManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, marketingCampaignTabs(user)),
  },
  {
    id: 'executive-management',
    label: 'Gestión de ejecutivos',
    path: 'executive-manager',
    icon: <DescriptionOutlinedIcon />,
    component: <ExecutiveManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, executiveManagerTabs(user)),
  },
  {
    id: 'customers-management',
    label: 'Gestión de clientes',
    path: 'customers-management',
    icon: <DescriptionOutlinedIcon />,
    component: <CustomersManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, customerManagerTabs(user)),
  },
  {
    id: 'invoices-management',
    label: 'Gestión de facturas',
    path: 'invoices-management',
    icon: <DescriptionOutlinedIcon />,
    component: <InvoicesManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, InvoicesManagerTabs(user)),
  },
  {
    id: 'ordering',
    label: 'Gestión de OC',
    path: 'ordering',
    icon: <DescriptionOutlinedIcon />,
    component: <OrderingManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, orderingTabs(user)) && getCountryFromTld() !== 'Mexico',
  },
  {
    id: 'whitelist',
    label: 'Whitelist',
    path: 'whitelist',
    icon: <ListIcon />,
    component: <WhitelistManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, whitelistManagerTabs(user)),
  },
  {
    id: 'simulations',
    label: 'Simulaciones',
    path: 'simulations',
    icon: <ListIcon />,
    component: <PreSimulationsManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, simulationTabs(user)),
  },
  {
    id: 'contactability',
    label: 'Contactabilidad',
    path: 'contactability',
    icon: <Compare />,
    component: <ContactabilityManager />,
    showInSidebar: true,
    show: hasShowSubRoutes(user, contactabilityManagerTabs(user)),
    disabled: false,
  },
  {
    id: 'whatsapp-conversations',
    label: 'Whatsapp',
    path: 'whatsapp-conversations',
    icon: <WhatsAppIcon sx={{ color: '#25d366' }} />,
    component: <WhatsAppManager />,
    showInSidebar: true,
    show: hasShowSubRoutes(user, whatsAppManagerTabs(user)),
    disabled: false,
  },
  {
    id: 'contracts-manager',
    label: 'Gestión de contratos',
    path: 'contracts-manager',
    icon: <DescriptionOutlinedIcon />,
    component: <ContractsManager />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, contractManagerTabs(user)),
  },
];

const commercialInvoiceRoutes = [
  {
    id: 'invoice-profile',
    label: 'Perfil de Factura',
    path: 'invoice-profile',
    icon: null,
    component: <InvoiceProfileManager />,
    disabled: false,
    showInSidebar: true,
    show: true,
  },
];

export {
  commercialRoutes,
  commercialInvoiceRoutes,
  InvoiceProfileManagerTabs,
  contactabilityManagerTabs,
  whatsAppManagerTabs,
  customerManagerTabs,
  executiveManagerTabs,
  InvoicesManagerTabs,
  orderingTabs,
  whitelistManagerTabs,
  contractManagerTabs,
};
