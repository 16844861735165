/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { GET_CUSTOMERS_COMPANY_FRAGMENT } from '@fingo/lib/graphql/customers/fragment';

export const LARA = gql`
  mutation Lara($masterEntityId: Int!) {
    lara(masterEntityId: $masterEntityId) {
      masterEntity {
        id
        company {
          ... GetCustomersCompanyFragment
        }
      }
    }
  }
  ${GET_CUSTOMERS_COMPANY_FRAGMENT}
`;
