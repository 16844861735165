import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { Executives, ExternalLeads } from '../components/executive-manager';

const executiveManagerTabs = (user) => [
  {
    id: 'external-leads',
    label: 'Leads externos',
    path: 'external-leads',
    component: <ExternalLeads />,
    disabled: false,
    show: userHasRoutePermission(user, 'customers.view_externallead'),
  },
  {
    id: 'executives',
    label: 'Ejecutivos',
    path: 'executives',
    component: <Executives />,
    disabled: false,
    show: userHasRoutePermission(user, 'users.view_userexecutive'),
  },
];

export default executiveManagerTabs;
