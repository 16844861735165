import React from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import { CONFIRMINGS_QUERY } from '@fingo/lib/graphql';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { CONFIRMING_CUSTOM_COLUMNS } from '../../../treasury/constants/treasury';
import ConfirmingProcessingFlexButtons from './ConfirmingProcessingFlexButtons';

const CONFIRMING_PROCESSING_COLUMNS = [
  'simpleFolio',
  'company_Name',
  'confirmingReceiver_Name',
  'amountWithIva',
  'advanceAmount',
  'offerDateToPay',
  'requirements',
  'confirmingCreatedAtStage',
];

export const MOBILE_HEADERS = [
  'simpleFolio',
  'company_Name',
  'advanceAmount',
];

const ConfirmingProcessing = () => (
  <DocumentList
    trackerId="Transfers-Confirming"
    type="confirming-transfer-panel"
    headerTitle="Confirmings en proceso de ser cedidas"
    queryDocument={CONFIRMINGS_QUERY}
    includeHeaders={CONFIRMING_PROCESSING_COLUMNS}
    mobileHeaders={MOBILE_HEADERS}
    showExportInvoice
    preColumns={useInvoicePreColumns(CONFIRMING_CUSTOM_COLUMNS)}
    flexEndButtons={() => (
      <ConfirmingProcessingFlexButtons />
    )}
    defaultFilterProps={{
      showStatesFilter: false,
      showDatesFilter: false,
      showRefresh: true,
      showCompanyIssuerFilter: false,
    }}
    onCompletedSetRows={
        (data) => data.invoices.edges.map((edge) => (edge.node.relatedConfirming))
      }
    showFilters
    customVariables={{
      allIssuedCompany: false,
      receiverId: null,
      companyId: null,
      receiver_Rut: null,
      dateIssued_Gte: null,
      dateIssued_Lte: null,
      assignmentSet_Status: 'Placed',
      relatedConfirming_Id_Isnull: false,
    }}
    initialOrderBy="company_MasterEntity_Name"
  />
);

export default ConfirmingProcessing;
