/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const UNEXPIRE_INVOICES = gql`
  mutation UnexpireInvoices($invoiceIds: [String]!) {
    unexpireInvoices(invoiceIds: $invoiceIds) {
      invoices {
        id
        status
      }
    }
  }
`;
