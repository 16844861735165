import { gql } from '@apollo/client';
import { MoneyFields } from '@fingo/lib/graphql/moneyFields';
import { CreditLineFields } from '@fingo/lib/graphql/customers/fragment';

export const RECOMMENDED_MASTER_ENTITIES = gql`
  query RecommendedMasterEntities(
    $first: Int
    $offset: Int
    $orderBy: String
    $countryId: Int
    $withInvoiceRecommendation: Boolean
    $executiveId: ID
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      orderBy: $orderBy
      company_ExecutiveAssignedId: $executiveId
      countryId: $countryId
      withInvoiceRecommendation: $withInvoiceRecommendation
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          displayNationalIdentifier
          creditLine {
            ...CreditLineFields
          }
          company {
            id
            documents {
              id
              globalAppId
              lastFile
              documentType
              lastDate
              documentDate
            }
            executiveAssigned {
              id
              firstName
              lastName
              completeName @client
              picture {
                name
                url
              }
            }
          }
        }
      }
    }
  }
  ${CreditLineFields}
`;

export const INVOICE_RECOMMENDATIONS = gql`
  query InvoiceRecommendations(
    $first: Int
    $offset: Int
    $companyId: Int
    $status: String
    $visible: Boolean
    $dateIssued: Date
  ) {
    invoiceRecommendations(
      first: $first
      offset: $offset
      status: $status
      visible: $visible
      invoice_DateIssued_Gte: $dateIssued
      invoice_CompanyId: $companyId
    ) {
      totalCount
      edges {
        node {
          id
          invoice {
            id
            folio
            dateIssued
            amountWithIva {
              ...MoneyFields
            }
            receiver {
              id
              name
              displayNationalIdentifier
            }
            preoffer {
              id
              monthlyRate
              defaultRate
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
`;
