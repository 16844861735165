import { gql } from '@apollo/client';

export const UPDATE_USER_EXECUTIVE = gql`
  mutation UpdateUserExecutive(
    $id: Int!
    $active: Boolean
    $hubspotId: Int
    $tycActiveAssignation: Boolean
    $loginActiveAssignation:Boolean
    $clickActiveAssignation:Boolean
  ) {
    updateUserExecutive(
        id: $id,
        input: {
          active: $active,
          hubspotId: $hubspotId,
          tycActiveAssignation: $tycActiveAssignation,
          loginActiveAssignation: $loginActiveAssignation,
          clickActiveAssignation: $clickActiveAssignation
        }
    ) {
    updateUserExecutive {
        id
        active
        hubspotId
        tycActiveAssignation
        loginActiveAssignation
        clickActiveAssignation
      }
    }
}`;

export const UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION = gql`
  mutation UpdateUserExecutiveAssignationProportion(
    $assignationType: String!
    $input: [UserExecutiveAssignationInputType]!
  ) {
    updateUserExecutiveAssignationProportion(
        assignationType: $assignationType,
        input: $input
    ) {
      success
      reason {
        id
        loginAssignationProportion
        clickAssignationProportion
      }
    }
  }
`;

export const BULK_ASSIGN_EXECUTIVE = gql`
  mutation bulkAssignExecutive(
    $assignations: Upload!
  ) {
    bulkAssignExecutive(
      assignations: $assignations
    ) {
      companies {
        id
        executiveAssigned {
          id
        }
      }
    }
  }
`;
