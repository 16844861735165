import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import ConversationsView from '../components/whatsapp/Content/ConversationsView';

const whatsAppManagerTabs = (user) => [
  {
    id: 'conversations',
    label: 'Whatsapp',
    path: 'conversations',
    component: <ConversationsView />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'notification_center.view_whatsappconversation'),
  },
];

export default whatsAppManagerTabs;
