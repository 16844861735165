import { gql } from '@apollo/client';
import { BaseContactsField } from '@fingo/lib/graphql/customers/fragment';

export const ExternalLeadFields = gql`
  fragment ExternalLeadFields on ExternalLeadType {
    id
    masterEntity {
      id
      rut
      name
      displayNationalIdentifier
      company {
        id
        rut
        executiveAssigned {
          id
          firstName
          lastName
          completeName @client
        }
      }
    }
    isAssigned
    source {
      id
      name
    }
    type {
      id
      name
    }
  }
`;

export const GET_EXTERNAL_LEADS = gql`
  query GetExternalLeads(
    $first: Int,
    $offset: Int,
    $globalFilter: String,
    $orderBy: String,
    $masterEntityId_In: [ID]
  ) {
    getExternalLeads(
      first: $first,
      offset: $offset,
      globalFilter: $globalFilter,
      orderBy: $orderBy,
      masterEntityId_In: $masterEntityId_In,
    ) {
      totalCount
      edges {
        node {
          ... ExternalLeadFields
        }
      }
    }
  }
  ${ExternalLeadFields}
`;

export const GET_EXTERNAL_LEADS_TEMPLATE = gql`
  query getExternalLeadsTemplate {
    getExternalLeadsTemplate
  }
`;

export const UPLOAD_EXTERNAL_LEADS_EXCEL = gql`
  mutation uploadExternalLeadsExcel($file: Upload!) {
    uploadExternalLeadsExcel(file: $file) {
      newLeads {
        ... ExternalLeadFields
      }
    }
  }
  ${ExternalLeadFields}
`;

export const UPLOAD_CONTACTS_EXCEL = gql`
  mutation uploadContactsExcel($file: Upload!) {
    uploadContactsExcel(file: $file) {
      newContacts {
        ... BaseContactsField
      }
    }
  }
  ${BaseContactsField}
`;

export const ADD_EXTERNAL_LEADS = gql`
  mutation addExternalLeads($externalLeads: [ExternalLeadInputType]!) {
    addExternalLeads(externalLeads: $externalLeads) {
      newLeads {
        ... ExternalLeadFields
      }
    }
  }
  ${ExternalLeadFields}
`;

export const ASSIGN_EXTERNAL_LEADS = gql`
  mutation assignExternalLeads($executiveId: Int!, $externalLeadIds: [Int]!) {
    assignExternalLeads(executiveId: $executiveId, externalLeadIds: $externalLeadIds) {
      assignedLeads {
        ... ExternalLeadFields
      }
    }
  }
  ${ExternalLeadFields}
`;
