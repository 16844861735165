import { gql } from '@apollo/client';
import { ContactsField } from '@fingo/lib/graphql/customers/fragment';
import { MailLogTypeFields } from '@fingo/lib/graphql/notification-center/fragments';
import { MARKETING_CAMPAIGN_FRAGMENT } from './fragments';

export const MARKETING_CAMPAIGNS = gql`
  query marketingCampaigns (
    $first: Int
    $offset: Int
    $id_In: [String]
    $id: ID
    $orderBy: String
  ) {
    marketingCampaigns (
      first: $first
      offset: $offset
      id_In: $id_In
      id: $id
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ... MarketingCampaignFields
        }
      }
    }
  }
  ${MARKETING_CAMPAIGN_FRAGMENT}
`;

export const MARKETING_CAMPAIGN_CONTACTS = gql`
  query marketingCampaignContact (
    $first: Int
    $after: String
    $offset: Int
    $orderBy: String
    $marketingCampaign_Id: Int
  ) {
    marketingCampaignContact (
      first: $first
      after: $after
      offset: $offset
      orderBy: $orderBy
      marketingCampaign_Id: $marketingCampaign_Id
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          contact {
            ... ContactsField
          }
          mailLog {
            ... MailLogTypeFields
          }
        }
      }
    }
  }
  ${ContactsField}
  ${MailLogTypeFields}
`;

export const MARKETING_CAMPAIGN_SUMMARY = gql`
  query marketingCampaignSummary(
    $marketingCampaignId: Int!
  ) {
    marketingCampaignSummary(
      marketingCampaignId: $marketingCampaignId
    ) {
      event
      count
    }
  }
`;
