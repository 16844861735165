/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const MARKETING_CAMPAIGN_FRAGMENT = gql`
fragment MarketingCampaignFields on MarketingCampaignType {
  id
  status {
    id
    status
  }
  mail {
    id
    emailName
  }
  createdAt
  fromMail
  campaignSentDatetime
  scheduledMailingDate
  country {
    id
    name
  }
}
`;
