import { ConfirmingPortfolioFilters } from '@fingo/lib/views';
import Add from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';

const CustomPayrollActions = ({ filters, setFilters }) => (
  <Stack direction="row" spacing={1} width="480px">
    <FormControl fullWidth>
      <InputLabel>Filtrar estado operación</InputLabel>
      <Select
        variant="standard"
        disableUnderline
        fullWidth
        value={filters.payrollStatus}
        label="Filtrar estado operación"
        onChange={
                ({ target }) => setFilters(
                  (old) => ({ ...old, payrollStatus: target.value }),
                )
              }
      >
        <MenuItem value="">
          <em>Quitar filtros</em>
        </MenuItem>
        <MenuItem value="pendingApproval">
          Peaje pendiente
        </MenuItem>
        <MenuItem value="pendingCession">
          Pendiente de cesión
        </MenuItem>
        <MenuItem value="pendingTransfer">
          Pendiente de transferencia
        </MenuItem>
        <MenuItem value="completed">
          Transferido
        </MenuItem>
        <MenuItem value="finished">
          Completado
        </MenuItem>
      </Select>
    </FormControl>
    <FormControl fullWidth>
      <InputLabel>Filtrar estado nómina</InputLabel>
      <Select
        fullWidth
        variant="standard"
        disableUnderline
        value={filters.status_Status}
        label="Filtrar estado nómina"
        onChange={
                ({ target }) => setFilters(
                  (old) => ({ ...old, status_Status: target.value }),
                )
              }
      >
        <MenuItem value="">
          <em>Quitar filtros</em>
        </MenuItem>
        <MenuItem value="PENDING">
          Creada
        </MenuItem>
        <MenuItem value="INITIATED">
          En proceso
        </MenuItem>
        <MenuItem value="COMPLETED">
          Completadas
        </MenuItem>
        <MenuItem value="DELETED">
          Eliminadas
        </MenuItem>
      </Select>
    </FormControl>
    <ConfirmingPortfolioFilters
      sx={{ }}
      endIcon={<Add color="primary" />}
    />
  </Stack>
);

CustomPayrollActions.propTypes = {
  filters: PropTypes.shape({
    payrollStatus: PropTypes.string,
    status_Status: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default CustomPayrollActions;
