import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { Contactability } from '../components/contactability-manager';

const contactabilityManagerTabs = (user) => [
  {
    id: 'contactability-company',
    label: 'Contactabilidad',
    path: 'company',
    component: <Contactability />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'auth.view_commercial_contactability_panel'),
  },
];

export default contactabilityManagerTabs;
