import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { Contracts } from '../components/customers-manager';

const contractManagerTabs = (user) => [
  {
    id: 'conversations',
    label: 'Contratos',
    path: 'contracts-manager',
    component: <Contracts />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'customers.view_companyframeworkcontractrequest'),
  },
];

export default contractManagerTabs;
