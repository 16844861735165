import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import {
  Customers,
  CompanyRecommendations,
} from '../components/customers-manager';

const customerManagerTabs = (user) => [
  {
    id: 'customer-manager',
    label: 'Gestión de clientes',
    path: 'customers-manager',
    component: <Customers />,
    disabled: false,
    show: userHasRoutePermission(user, 'customers.view_company'),
  },
  {
    id: 'company-recommendations',
    label: 'Gestiones recomendadas',
    path: 'company-recommendations',
    component: <CompanyRecommendations />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.view_invoicerecommendation'),
  },
];

export default customerManagerTabs;
