import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import {
  PendingDocumentsManager,
  PreoffersManager,
  OffersManager,
  OperationsManager,
  CollectionManager,
  HistoricManager,
} from '../components/invoices-manager';

const InvoicesManagerTabs = (user) => [
  {
    id: 'preoffers-manager',
    label: 'Preofertas',
    path: 'preoffers-manager',
    component: <PreoffersManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.view_invoice'),
  },
  {
    id: 'pending-documents-manager',
    label: 'P. Documentos',
    path: 'pending-documents-manager',
    component: <PendingDocumentsManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.view_invoice'),
  },
  {
    id: 'offers-manager',
    label: 'Ofertas',
    path: 'offers-manager',
    component: <OffersManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.view_invoice'),
  },
  {
    id: 'operations-manager',
    label: 'Operaciones',
    path: 'operations-manager',
    component: <OperationsManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.view_invoice'),
  },
  {
    id: 'collection-manager',
    label: 'Cobranza',
    path: 'collection-manager',
    component: <CollectionManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.view_invoice'),
  },
  {
    id: 'historic-manager',
    label: 'Histórico/Rechazos/Expiraciones',
    path: 'historic-manager',
    component: <HistoricManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.view_invoice'),
  },
];

export default InvoicesManagerTabs;
