import React from 'react';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import Email from '@mui/icons-material/Email';
import Wallet from '@mui/icons-material/Wallet';
import { hasShowSubRoutes, userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { FingoPaper } from '@fingo/lib/components/layout';
import ConfirmingMailing from '../components/ConfirmingMail/ConfirmingMail';
import ConfirmingProcessing from '../components/Processing/ConfirmingProcessing';
import ConfirmingPayroll from '../components/ConfirmingPayroll/ConfirmingPayroll';

export const confirmingTabs = (user) => [
  {
    id: 'confirming-processing-tab',
    label: 'En proceso',
    path: 'processing',
    component: <ConfirmingProcessing />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'confirming.add_confirming'),
  },

];

export const confirmingEmailsTabs = (user) => [
  {
    id: 'comfirming-mail',
    label: 'Emails de confirming',
    path: 'email',
    component: <ConfirmingMailing />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'confirming.add_confirming'),
  },
];

const confirmingPayrollTabs = (user) => [
  {
    id: 'comfirming-payrolls',
    label: 'Nóminas de confirming',
    path: 'payrolls',
    component: <ConfirmingPayroll />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'confirming.add_confirming'),
  },
];

export const confirmingRoutes = (user) => [
  {
    id: 'confirmings',
    label: 'Confirming',
    path: 'confirmings',
    icon: <MonetizationOn />,
    component: <FingoPaper tabs={confirmingTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, confirmingTabs(user)),
  },
  {
    id: 'payroll',
    label: 'Nóminas de confirming',
    path: 'payroll',
    icon: <Wallet />,
    component: <FingoPaper tabs={confirmingPayrollTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, confirmingPayrollTabs(user)),
  },
  {
    id: 'emails-confirming',
    label: 'Gestor de emails',
    path: 'emails',
    icon: <Email />,
    component: <FingoPaper tabs={confirmingEmailsTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, confirmingEmailsTabs(user)),
  },
];
