import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import CreateSimulation from '../components/simulations/CreateSimulation';
import ViewSimulation from '../components/simulations/ViewSimulation';

const simulationTabs = (user) => [
  {
    id: 'create',
    label: 'Crear simulación',
    path: 'create',
    component: <CreateSimulation />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.add_factoringsimulation'),
  },
  {
    id: 'list',
    label: 'Simulaciones',
    path: 'list',
    component: <ViewSimulation />,
    disabled: false,
    show: userHasRoutePermission(user, 'invoices.add_factoringsimulation'),
  },
];

export default simulationTabs;
