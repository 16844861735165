import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import React from 'react';
import {
  OrderingCollectionsManager,
  OrderingOffersManager,
  OrderingOperationsManager,
  SimulationsManager,
} from '../components/ordering-manager';

const orderingTabs = (user) => [
  {
    id: 'purchase-orders-simulations',
    label: 'Simulaciones',
    path: 'purchase-orders-simulations',
    component: <SimulationsManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'ordering.view_purchaseorder'),
  },
  {
    id: 'purchase-orders-offers',
    label: 'Ofertas',
    path: 'purchase-orders-offers',
    component: <OrderingOffersManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'ordering.view_purchaseorder'),
  },
  {
    id: 'purchase-orders-operations',
    label: 'Operaciones',
    path: 'purchase-orders-operations',
    component: <OrderingOperationsManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'ordering.view_purchaseorder'),
  },
  {
    id: 'pending-purchase-orders',
    label: 'Pagar con factura',
    path: 'pending-purchase-orders',
    component: <OrderingCollectionsManager />,
    disabled: false,
    show: userHasRoutePermission(user, 'ordering.view_purchaseorder'),
  },
];

export default orderingTabs;
